@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif");

body, html {
  font-family: 'Roboto Serif', sans-serif;
  background-color: #F2F2F2;
}

.profile{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.profile-list {
  display: flex;
  flex-wrap: wrap;
}

.profile-items {
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: center;
}

.name-heading {
  font-size: x-large;
  margin-top: 0;
  margin-bottom: 0;
}

#Heading {
  text-align: center;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.job-desc-item {
  margin-top: .25em;
  margin-bottom: .25em;
}

.job-date {
  margin-bottom: 0em;
}

.grid-item {
  font-size: medium;
  width: 225px;
  margin-right:5em;
}

h1 {
  font-size: larger;
}

strong {
  font-size: medium;
}

p {
  font-size: medium;
}

.school {
  margin-top:0;
}

#Main {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 1000px;
}

.section-heading {
  margin-bottom: 1.5em;
}


.sub-section-heading {
  margin-bottom: 1em;
}

.section {
  margin-bottom: 2em;
}

.sub-section {
  margin-bottom: 2em;
}

.section > p {
  font-size: medium;
}

.list-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0em;
  margin-bottom: 0em ;
  width: 100%;
}

.job-company, .job-location, .job-date, .job-title {
  margin-top:0em;
  margin-bottom:0em;
}
